/* eslint-disable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faCheck,
  faFilter,
  faFileExcel,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import Lottie from 'lottie-react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import Swal from 'sweetalert2';
import { isNotNullUndefinedOrEmpty } from '../../../components/Validators';
import { getAllRegionalHeadIndentsApprovals, updateRegionalHeadHnlRequests, updateHnlIndentRequests, exporRegionalHeadHnlIndentingApproval, getPlantAndMaterialCodes, getPlantCapacity } from '../../../store/modules/product/actions';
import {
  selectIsLoading,
  selectProducts,
} from '../../../store/modules/product/selectors';
import { ToastContainer, toast } from 'react-toastify';
import Search from '../../../components/Search';
import LoadingModal from '../../../components/LoadingModal';
import withScreenRefreshDetection from '../../../utils/withScreenRefreshDetection';
import EmptyInventory from '../../../assets/animations/EmptyInventory.json';
import '../Hnlindenting.scss';

const Tickets = (props) => {
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const [query, setQuery] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [storePageSizeValue, setStorePageSizeValue] = useState(30);
  const [pageNum, setPageNum] = useState(1);
  const [products, setProducts] = useState([]);
  const [productsController, setProductsController] = useState({
    thresholds: {},
    quantities: {},
    vehicleNumber: {},
    driverMobileNumber: {},
    requiredDate: {},
    dispatchDate: {},
    dispatchQuantity: {}
  });
  const data = useSelector((state) => selectProducts(state));
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [plantCode, setPlantCode] = useState(null);
  const [materialCode, setMaterialCode] = useState(null);
  const [feedType, setFeedType] = useState(null);
  const [feedTypes, setFeedTypes] = useState([]);
  const [plantCodes, setPlantCodes] = useState([]);
  const [materialCodes, setMaterialCodes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const values = {
          ...(role === 'hnl-admin'
            ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
            : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
          ),
        }
        const response = await dispatch(getPlantAndMaterialCodes(values));
        console.log('Fetched data:', response);

        if (response) {
          const { plantCodes, materialCodes, feedTypes } = response;
          setPlantCodes(plantCodes);
          setMaterialCodes(materialCodes);
          setFeedTypes(feedTypes);
        }
      } catch (error) {
        console.error('Error fetching plant and material codes:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleCallBack = (response) => {
    showCapacityDifferece(response?.data)
    if (response) {
      const values = {
        page: pageNum,
        limit: storePageSizeValue,
        ...(role === 'hnl-admin'
          ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
          : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
        ),
        plantCode,
        materialCode,
        feedType,
      };
      dispatch(getAllRegionalHeadIndentsApprovals(values));
    }
  };

  const handleButtonClick = async (material, thresholdValue, quantityValue, vehicleNumber, driverMobileNumber, requiredDate, dispatchDate, dispatchQuantity, role, status) => {
    if(role === 'hnl-admin'){
      quantityValue = dispatchQuantity
    }
    if (status === 'approved' && requiredDate == undefined && role === 'regional_pi') {
      alert("Please enter Required date");
      return;
    }
    if (status === 'approved' && quantityValue === "0" && role === 'regional_pi') {
      alert("Indent quantity should be more than zero");
      return;
    } else if (status === 'approved' && quantityValue === "" && role === 'regional_pi') {
      alert("Please enter indent quantity");
      return;
    }
    if (status === 'approved' && role === 'hnl-admin') {
      if (dispatchQuantity === "0") {
        alert("Dispatch quantity should be more than zero");
        return;
      } else if (dispatchQuantity === "") {
        alert("Dispatch quantity should not be empty");
        return;
      } else if (dispatchDate === undefined) {
        alert("Please enter Dispatch date");
        return;
      }
    }
    if (status === 'approved') {
      const values = {
        id: material?.id,
        threshold: thresholdValue,
        indentQuantity: quantityValue ? quantityValue : material?.indentQuantity,
        ...(role === 'hnl-admin'
          ? {
            hnlStatus: status,
            vehicleNumber,
            driverMobileNumber,
            dispatchDate,
            dispatchQuantity
          }
          : {
            rHStatus: status,
            requiredDate
          }
        ),
      };
      const array = [
        Object.entries(values)
          .filter(([_, value]) => isNotNullUndefinedOrEmpty(value))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {}),
      ];

      await checkPlantCapacity(material, array)

    } else {
      Swal.fire({
        title: `Why reject ${material?.materialName ?? 'current item'}? Can you share your reasons?`,
        input: 'textarea',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
      }).then((result) => {
        if (result?.isConfirmed) {
          if (result?.value) {
            const values = {
              id: material?.id,
              threshold: thresholdValue,
              indentQuantity: quantityValue,
              ...(role === 'hnl-admin'
                ? { hnlStatus: status }
                : { rHStatus: status }
              ),
              comment: result?.value,
            };
            const array = [
              Object.entries(values)
                .filter(([_, value]) => isNotNullUndefinedOrEmpty(value))
                .reduce((acc, [key, value]) => {
                  acc[key] = value;
                  return acc;
                }, {}),
            ];
            dispatch(role === 'hnl-admin' ? updateHnlIndentRequests(array, handleCallBack) : updateRegionalHeadHnlRequests(array, handleCallBack));
          } else {
            Swal.fire({
              title: 'Please enter the reason for rejection',
              icon: 'warning',
              toast: true,
            });
          }
        }
      });
    }
  };

  const checkPlantCapacity = async (material, array) => {
    const { plantCode, uom } = material;
    const { indentQuantity } = array[0];
    const values = { indentQuantity, plantCode, uom };

    const res = await dispatch(getPlantCapacity(values))

    const { canProceed, indentLoad, plantCapacity } = res
    if (!canProceed && role ==='regional_pi') {
      Swal.fire({
        title: 'Not allowed',
        icon: 'error',
        text: `Current Indent Capacity - ${indentLoad} exceeds the Plant Capacity - ${plantCapacity} `
      });

    } else {
      Swal.fire({
        title: 'Confirm Approval?',
        text: `Are you sure you want to approve the ${material?.materialName ?? 'current item'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(role === 'hnl-admin' ? updateHnlIndentRequests(array, handleCallBack) : updateRegionalHeadHnlRequests(array, handleCallBack));
        }
      });
    }

  }

  const exportExcell = () => {
    const status = tabIndex === 0 ? 'pending' : 'approved';
    dispatch(exporRegionalHeadHnlIndentingApproval({ status, plantCode, materialCode, feedType }));
  };

  const handleOnFilter = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Enter Plant Code & Material Code',
      html: `
        <select id="swal-input1" class="swal2-input">
          <option value="">Select Plant Code</option>
          ${plantCodes.map(item => `<option value="${item}" ${item === plantCode ? 'selected' : ''}>${item}</option>`).join('')}
        </select>
        <select id="swal-input3" class="swal2-input">
          <option value="">Select Feed Type</option>
          ${feedTypes.map(item => `<option value="${item}" ${item === feedType ? 'selected' : ''}>${item}</option>`).join('')}
        </select>
         <select id="swal-input2" class="swal2-input">
          <option value="">Select Material Code</option>
          ${materialCodes.map(item => `<option value="${item}" ${item === materialCode ? 'selected' : ''}>${item}</option>`).join('')}
        </select>
      `,
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      footer: `
        <button id="clear-filters" style="
          background-color: #d33;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 24px;
          font-size: 16px;
          cursor: pointer;
          margin-top: 10px;
        ">Clear Filters</button>
      `,
      focusConfirm: false,
      preConfirm: () => {
        const input1 = document.getElementById('swal-input1').value;
        const input2 = document.getElementById('swal-input2').value;
        const input3 = document.getElementById('swal-input3').value;
        if (!input1 && !input2 && !input3) {
          Swal.showValidationMessage('At least one field must be filled');
          return null;
        }
        return [input1, input2, input3];
      },
      didRender: () => {
        document.getElementById('clear-filters').addEventListener('click', () => {
          clearFilters();
          Swal.close();
        });
      }
    });

    if (formValues) {
      setPlantCode(formValues[0]);
      setMaterialCode(formValues[1]);
      setFeedType(formValues[2]);
      const values = {
        page: pageNum,
        limit: storePageSizeValue,
        ...(role === 'hnl-admin'
          ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
          : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
        ),
        plantCode: formValues[0],
        materialCode: formValues[1],
        feedType: formValues[2]
      };
      dispatch(getAllRegionalHeadIndentsApprovals(values));
    }
  };

  const clearFilters = () => {
    setPlantCode(null);
    setMaterialCode(null);
    setFeedType(null);
    const values = {
      page: pageNum,
      limit: storePageSizeValue,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
      plantCode: null,
      materialCode: null,
      feedType: null
    };
    dispatch(getAllRegionalHeadIndentsApprovals(values));
  };

  const handleInputChange = (row, key, value) => {
    const updatedState = { ...productsController };
    updatedState[key][row.original.id] = value;

    setProductsController((prevState) => ({
      ...prevState,
      ...updatedState,
    }));

    const newData = { ...data };
    const updatedRow = { ...row.original, [key]: value };
    const updatedPaginate = newData?.paginate?.map((rowData) => (rowData.id === updatedRow.id ? updatedRow : rowData));
    newData.paginate = updatedPaginate;
    setProducts(newData);
  };
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={(e) => {
              const { checked } = e.target;
              setSelectAll(checked);
              if (checked) {
                setSelectedRows(data.paginate.map((row) => row.id));
              } else {
                setSelectedRows([]);
              }
            }}
          />
        ),
        accessor: 'select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.original.id)}
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setSelectedRows([...selectedRows, row.original.id]);
              } else {
                setSelectedRows(
                  selectedRows.filter((id) => id !== row.original.id),
                );
              }
            }}
          />
        ),
      },
      {
        Header: 'ORDER ID',
        accessor: 'id',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'INDENT ID',
        accessor: 'indentId',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      ...(role === 'hnl-admin' ? [
        {
          Header: 'ORDER DATE',
          accessor: 'orderedDate',
          Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
        },
      ] : []),
      {
        Header: 'REGION NAME',
        accessor: 'regionName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'PLANT CODE',
        accessor: 'plantCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'PLANT NAME',
        accessor: 'plantName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: () => (
          <span>
            Required Date <span className="required-mark">*</span>
          </span>
        ),
        accessor: 'requiredDate',
        Cell: ({ row }) => (
          role === 'regional_pi' ? (
            <input
              type='date'
              className="form__field"
              value={productsController?.requiredDate[row?.original?.id] ?? row?.original?.requiredDate}
              onChange={(e) => handleInputChange(row, 'requiredDate', e.target.value)}
              min={todayString}
              required
            />
          ) : <p>{row?.original?.requiredDate}</p>
        )
      },
      {
        Header: 'PRODUCT CATEGORY',
        accessor: 'type',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'PRODUCT NAME',
        accessor: 'materialName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'MATERIAL CODE',
        accessor: 'materialCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'UNIT OF MEASUREMENT',
        accessor: 'uom',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'INDENT QUANTITY',
        accessor: 'indentQuantity',
        Cell: ({ row }) => (
          role === 'hnl-admin' ? <p>{row?.original?.currentQuantity}</p> : (
            <input
              type="number"
              className="form__field"
              value={productsController?.quantities[row?.original?.id] ?? row?.original?.indentQuantity}
              onChange={(e) => handleInputChange(row, 'quantities', e.target.value)}
            />
          )
        ),
      },
      ...(role === "hnl-admin" ? [
        {
          Header: 'DISPATCH QUANTITY',
          accessor: 'dispatchQuantity',
          Cell: ({ row }) => (
            role === 'hnl-admin' ? (
              <input
                className="form__field"
                value={productsController?.dispatchQuantity[row?.original?.id] ?? row?.original?.dispatchQuantity}
                onChange={(e) => handleInputChange(row, 'dispatchQuantity', e.target.value)}
              />
            ) : <p>{row?.original?.dispatchQuantity}</p>)
        },
      ] : []),
      {
        Header: 'CURRENT INVENTORY',
        accessor: 'ohs',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      {
        Header: 'TRUCK CAPACITY',
        accessor: 'truckCapacity',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
      },
      ...(role === 'hnl-admin' ? [
        {
          Header: 'VEHICLE NUMBER',
          accessor: 'vehicleNumber',
          Cell: ({ row }) => (
            role === 'hnl-admin' ? (
              <input
                className="form__field"
                value={productsController?.vehicleNumber[row?.original?.id] ?? row?.original?.vehicleNumber}
                onChange={(e) => handleInputChange(row, 'vehicleNumber', e.target.value)}
              />
            ) : <p>{row?.original?.vehicleNumber}</p>)
        },
        {
          Header: 'DRIVER MOBILE NUMBER',
          accessor: 'driverMobileNumber',
          Cell: ({ row }) => (
            role === 'hnl-admin' ? (
              <input
                className="form__field"
                value={productsController?.driverMobileNumber[row?.original?.id] ?? row?.original?.driverMobileNumber}
                onChange={(e) => handleInputChange(row, 'driverMobileNumber', e.target.value)}
              />
            ) : <p>{row?.original?.driverMobileNumber}</p>)
        },
        {
          Header: 'DISPATCH DATE',
          accessor: 'dispatchDate',
          Cell: ({ row }) => (
            <input
              type='date'
              className="form__field"
              value={productsController?.dispatchDate[row?.original?.id] ?? row?.original?.dispatchDate}
              onChange={(e) => handleInputChange(row, 'dispatchDate', e.target.value)}
              min={todayString}
            />
          )
        }] : []),
      {
        Header: 'ACTION TO BE TAKEN',
        Cell: ({ row }) => (
          <div className="buttons">
            <button
              type="button"
              className="mini-button green"
              onClick={() => handleButtonClick(row?.original, productsController?.thresholds[row.original.id], productsController?.quantities[row?.original?.id], productsController?.vehicleNumber[row?.original?.id], productsController?.driverMobileNumber[row?.original?.id], productsController?.requiredDate[row?.original?.id], productsController?.dispatchDate[row?.original?.id], productsController?.dispatchQuantity[row.original.id], role, 'approved')}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button
              type="button"
              className="mini-button danger"
              onClick={() => handleButtonClick(row?.original, productsController?.thresholds[row.original.id], productsController?.quantities[row?.original?.id], productsController?.vehicleNumber[row?.original?.id], productsController?.driverMobileNumber[row?.original?.id], productsController?.quantities[row?.original?.id], productsController?.dispatchDate[row?.original?.id], productsController?.dispatchQuantity[row.original.id], role, 'rejected')}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        ),
      },
    ],
    [selectAll, selectedRows, data],
  );

  const finishedColumns = useMemo(
    () => [{
      Header: 'ORDER ID',
      accessor: 'id',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    }, {
      Header: 'INDENT ID',
      accessor: 'indentId',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'ORDER DATE',
      accessor: 'orderedDate',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'REGION NAME',
      accessor: 'regionName',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'PLANT CODE',
      accessor: 'plantCode',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'PLANT NAME',
      accessor: 'plantName',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'REQUIRED DATE',
      accessor: 'requiredDate',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'PRODUCT CATEGORY',
      accessor: 'type',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'PRODUCT NAME',
      accessor: 'materialName',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'MATERIAL CODE',
      accessor: 'materialCode',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'UNIT OF MEASUREMENT',
      accessor: 'uom',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'TRUCK CAPACITY',
      accessor: 'truckCapacity',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'INDENT QUANTITY',
      accessor: 'currentQuantity',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'DISPATCH QUANTITY',
      accessor: 'dispatchQuantity',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'VEHICLE NUMBER',
      accessor: 'vehicleNumber',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'DRIVER MOBILE NUMBER',
      accessor: 'driverMobileNumber',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'REJECTED REASON',
      accessor: 'comment',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'DISPATCH DATE',
      accessor: 'dispatchDate',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : ""),
    },
    {
      Header: 'REGIONAL STATUS',
      accessor: 'rHStatus',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    }, {
      Header: 'HNL STATUS',
      accessor: 'hnlStatus',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    },
    {
      Header: 'OVERALL STATUS',
      accessor: 'status',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    }],
    [],
  );

  const isLoading = useSelector((state) => selectIsLoading(state));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: tabIndex === 0 ? columns : finishedColumns,
    data:
      data && data?.paginate && data?.paginate?.length
        ? data?.paginate?.map((item) => ({
          ...item,
          phone: item?.phone?.replace('+91', ''),
        }))
        : [],
  });

  const nextPage = (page) => {
    setPageNum(page);
    const nextPageVal = {
      page,
      limit: storePageSizeValue,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
      plantCode,
      materialCode,
      feedType,
    };
    dispatch(getAllRegionalHeadIndentsApprovals(nextPageVal));
  };

  const previousPage = (page) => {
    setPageNum(page);
    const prevPageVal = {
      page,
      limit: storePageSizeValue,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
      plantCode,
      materialCode,
      feedType,
    };
    dispatch(getAllRegionalHeadIndentsApprovals(prevPageVal));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    const values = {
      prodName: query,
      page: pageNum,
      limit: storePageSizeValue,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
    };
    setPlantCode(null);
    setMaterialCode(null);
    setFeedType(null);
    dispatch(getAllRegionalHeadIndentsApprovals(values));
  };

  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 30);
  const handleOnPageSizeSelected = (event) => {
    const newSize = Number(event?.target?.value);
    setStorePageSizeValue(newSize);
    const values = {
      page: pageNum,
      limit: newSize,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
      plantCode,
      materialCode,
      feedType,
    };
    dispatch(getAllRegionalHeadIndentsApprovals(values));
  };

  const handleOnBulkApproval = (event) => {
    const array = selectedRows.map((id) => {
      const thresholdValue = productsController?.thresholds[id];
      const quantityValue = productsController?.quantities[id];
      const vehicleNumber = productsController?.vehicleNumber[id];
      const driverMobileNumber = productsController?.driverMobileNumber[id];
      const requiredDate = productsController?.requiredDate[id];
      const dispatchDate = productsController?.dispatchDate[id];
      const dispatchQuantity = productsController?.dispatchQuantity[id];

      return {
        id,
        threshold: thresholdValue,
        indentQuantity: quantityValue,
        vehicleNumber,
        driverMobileNumber,
        requiredDate,
        dispatchDate,
        dispatchQuantity,
        ...(role === 'hnl-admin'
          ? { hnlStatus: event }
          : { rHStatus: event }
        ),
      };
    });

    let invalidEntries = [];
    let alertMessage = '';
    if (role === 'regional_pi') {
      invalidEntries = array.filter(item => !item.requiredDate || item.indentQuantity === null || item.indentQuantity === 0);
      alertMessage = 'Some items have missing or invalid required dates or indent quantities. Please correct them before proceeding.';
    } else if (role === 'hnl-admin') {
      invalidEntries = array.filter(item =>
        !item.dispatchDate || item.dispatchQuantity === null || item.dispatchQuantity === 0 ||
        !item.vehicleNumber || !item.driverMobileNumber
      );
      alertMessage = 'Some items have missing or invalid dispatch dates, dispatch quantities, vehicle numbers, or driver mobile numbers. Please correct them before proceeding.';
    }

    if (invalidEntries.length > 0) {
      Swal.fire({
        title: 'Invalid Entries Detected',
        text: alertMessage,
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (event === 'approved') {
      Swal.fire({
        title: 'Confirm Approval?',
        text: 'Are you sure you want to approve all the selected items?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(role === 'hnl-admin' ? updateHnlIndentRequests(array, handleCallBack) : updateRegionalHeadHnlRequests(array, handleCallBack));
        }
      });
    } else {
      Swal.fire({
        title: 'Why reject all these items? Can you share a valid reason?',
        input: 'textarea',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const rejectionArray = selectedRows.map((id) => {
              const thresholdValue = productsController?.thresholds[id];
              const quantityValue = productsController?.quantities[id];
              return {
                id,
                threshold: thresholdValue,
                indentQuantity: quantityValue,
                ...(role === 'hnl-admin'
                  ? { hnlStatus: event }
                  : { rHStatus: event }
                ),
                comment: result.value,
              };
            });
            dispatch(role === 'hnl-admin' ? updateHnlIndentRequests(rejectionArray, handleCallBack) : updateRegionalHeadHnlRequests(rejectionArray, handleCallBack));
          } else {
            Swal.fire({
              title: 'You lack a valid rejection reason. Please try again.',
              icon: 'warning',
              toast: true,
            });
          }
        }
      });
    }
  };

  const arrayOfButtons = [
    {
      id: 0,
      name: 'Approve',
      className: 'button green',
      onClick: () => handleOnBulkApproval('approved'),
    },
    {
      id: 1,
      name: 'Reject',
      className: 'button danger',
      onClick: () => handleOnBulkApproval('rejected'),
    },
  ];

  useEffect(() => {
    setPlantCode(null);
    setMaterialCode(null);
    setFeedType(null);
    const values = {
      page: 1,
      limit: 30,
      ...(role === 'hnl-admin'
        ? { hnlStatus: tabIndex === 0 ? 'pending' : 'approved' }
        : { rHStatus: tabIndex === 0 ? 'pending' : 'approved' }
      ),
    };
    dispatch(getAllRegionalHeadIndentsApprovals(values));
  }, [dispatch, tabIndex]);

  const showCapacityDifferece = (data) => {
    const { totalLoad, truckLoad } = data;
    const { truckCapacity } = truckLoad;
    if (totalLoad < truckCapacity) {
      toast(`Capactiy Difference - ${truckCapacity - totalLoad}`, {
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <div className="hnl-indenting-dashboard">
      <div className="row middle">
        <div className="col-12 col-md-10">
          {/* <Search
            value={query}
            label="Find Farmers By Name, Vendor Code, or Phone"
            onChangeText={handleChange}
            onSubmit={handleOnSearch}
          /> */}
        </div>
        <ToastContainer />
        <div className="col-12 col-md-2">
          <div className="buttons">
            <button
              type="button"
              className="button primary"
              onClick={handleOnFilter}
            >
              <FontAwesomeIcon icon={faFilter} className="button__icon" />
              Filter
            </button>
            <button type="button" className="button green" onClick={exportExcell}>
              <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
              Export
            </button>
          </div>
        </div>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="row between tab-list">
          <Tab className="col-6 tab">
            <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
              Ongoing Inventory
            </div>
          </Tab>
          <Tab className="col-6 tab">
            <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
              Finished Inventory
            </div>
          </Tab>
        </TabList>
        <TabPanel className="order-tab-panel">
          <div className="row end">
            {selectedRows?.length > 0 ? (
              <div className="col-12 col-md-2">
                <div className="buttons">
                  {arrayOfButtons
                    ?.filter((item) => {
                      if (item?.name === 'Ticket' && role !== 'call_center') {
                        return false;
                      }
                      return true;
                    })
                    .map((item) => (
                      <button
                        key={item?.id}
                        type="button"
                        className={item?.className}
                        onClick={item?.onClick}
                      >
                        {item?.name}
                      </button>
                    ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="table-container">
            {data && data?.paginate?.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="table-footer__title">
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            - data.Previous.limit
                            + 1
                            : 1}
                          {' '}
                          to
                          {' '}
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            : storePageSizeValue}
                          {' '}
                          {' '}
                          {`of ${pageNum}`}
                          {' '}
                          {''}
                          <span className="form__label">Page Size : </span>
                          <select
                            onChange={handleOnPageSizeSelected}
                            className="dropdown-menu"
                          >
                            {pageSize?.map((item, index) => (
                              <option
                                key={index.toString()}
                                value={item}
                                className="dropdown-menu"
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(1)}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? previousPage(data.Previous.page) : {})}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {data && data.Next
                              ? data.Next.page - 1
                              : data && data.Previous
                                ? data.Previous.page + 1
                                : 1}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup?.headers?.map((column, index) => (
                            <th
                              key={index.toString()}
                              {...column.getHeaderProps()}
                              style={{ fontSize: 12 }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{ fontSize: 13, fontWeight: 400 }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row middle h-200">
                <div className="d-flex-col center">
                  <Lottie animationData={EmptyInventory} loop={false} className="noUsersIcon" />
                  <p className="noRecordsFound">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel className="order-tab-panel">
          <div className="table-container">
            {data && data?.paginate?.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="table-footer__title">
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            - data.Previous.limit
                            + 1
                            : 1}
                          {' '}
                          to
                          {' '}
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            : storePageSizeValue}
                          {' '}
                          {' '}
                          {`of ${pageNum}`}
                          {' '}
                          {''}
                          <span className="form__label">Page Size : </span>
                          <select
                            onChange={handleOnPageSizeSelected}
                            className="dropdown-menu"
                          >
                            {pageSize?.map((item, index) => (
                              <option
                                key={index.toString()}
                                value={item}
                                className="dropdown-menu"
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(1)}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? previousPage(data.Previous.page) : {})}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {data && data.Next
                              ? data.Next.page - 1
                              : data && data.Previous
                                ? data.Previous.page + 1
                                : 1}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup?.headers?.map((column, index) => (
                            <th
                              key={index.toString()}
                              {...column.getHeaderProps()}
                              style={{ fontSize: 12 }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{ fontSize: 13, fontWeight: 400 }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row middle h-200">
                <div className="d-flex-col center">
                  <Lottie animationData={EmptyInventory} loop={false} className="noUsersIcon" />
                  <p className="noRecordsFound">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </TabPanel>
      </Tabs>
      <LoadingModal show={isLoading} />
    </div>
  );
};

export default withScreenRefreshDetection(Tickets);